.App {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #111;
}
h1 {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}
.recipe-form {
  font-size: 1rem;
  padding: 0 0.5rem;
}
.ingredient {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 1rem;
  height: 3rem;
}
.ingredient:nth-child(odd) {
  background-color: #eeeeee;
}
.ingredient input[type='number'] {
  font-size: 1.2rem;
  max-width: 5rem;
  padding: 0 0.5rem;
  text-align: right;
  -moz-appearance: textfield;
}
.ingredient .quantity-description {
  padding: 0 0.5rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.recipe-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
}
button[type='reset'] {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  background-color: #eeeeee;
  color: #111;
}
@media screen and (min-width: 37.5em) {
  h1 {
    font-size: 2.5rem;
  }
  .recipe-form {
    width: 36rem;
    margin: 0 auto;
    padding: 0;
  }
}
